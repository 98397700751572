





























































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
import filesDialog from '@/components/wechatEditor/rolePlayfiles.vue';
@Component({
    components: {
        filesDialog
    }
})
export default class imgtextstatistics extends Vue {
    [x: string]: any;
    public form: any = {
        pageSize: 10, currentPage: 1
    };
    public dialogVisible: Boolean = false;
    public datacreateTime: any[] = [];
    private tableData: any[] = [];
    public loading: Boolean = false;
    public fileList: any[] = [];
    public actionUrl: any = '';
    public formData: any = {
        filessrc: '',
    };
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public async created(): Promise<void> {
        await this.getList();
    }
    //获取数据源
    public async getList() {
        var that = this, dataJson = {};
        that.loading = true;
        if (that.datacreateTime && that.datacreateTime.length > 0) {
            that.form.endTime = that.datacreateTime[1]
            that.form.startTime = that.datacreateTime[0]
        } else {
            that.form.endTime = ''
            that.form.startTime = ''
        }
        const res = await this.statisticsApi.getuserspage(that.form);
        that.tableData = res.records;
        // for (var i = 0; i < that.tableData.length; i++) {
        //     var moment = require('moment')
        //     const fmt = 'YYYY-MM-DD HH:mm:ss'
        //     that.tableData[i].createTime =  moment.utc(that.tableData[i].createTime).local().format(fmt)
        // }
        this.form.total = res.total;
        this.form.pageSize = res.size;
        this.form.currentPage = res.current;
        that.loading = false;
    }
    //导出
    public exportClick() {
        var that = this, data_ = {}, str: any = '';
        if (that.tableData.length <= 0) {
            this.$message({
                type: 'error',
                message: '当前无下载内容！'
            })
            return;
        } else {

            const res = this.statisticsApi.RPdownLoad(str, '配岗名单');
        }
    }
    //上传
    public downloadClick() {
        var that = this, data_ = {}, str: any = '';
        that.dialogVisible = true;
        // const res = this.statisticsApi.RPUpload(data_);

    }
    // 导出拼接
    public splicing(data: any) {
        var str = [], strString = '';
        for (var key in data) {
            str.push(key + "=" + data[key])
        }
        strString = str.join('&')
        return strString;
    }
    public search() {
        //插件时间格式化
        if (this.datacreateTime && this.datacreateTime.length > 0) {
            this.form.startTime = this.datacreateTime[0];
            this.form.endTime = this.datacreateTime[1];
        } else {
            this.form.startTime = "";
            this.form.endTime = "";
        }
        this.form.current = 1
        this.getList()
    }
    //得到子组件传过来的值
    public getChild(val: any): void {
        var that = this;
        that.dialogVisible = false;
        that.getList();
    }
    // 重置
    public reset() {
        this.form = {
            pageSize: 10, currentPage: 1
        };
        this.datacreateTime = [];
        this.$refs.singleTable.clearSort();
        this.getList();
    }
    //分页相关
    public handleSizeChange(size: number) {
        this.form.pageSize = size;
        this.getList();
    }
    public handleCurrentChange(current: number) {
        this.form.currentPage = current;
        this.getList();
    }
    private mounted() {
        document.onkeydown = (e) => {
            let key = (window.event as any).keyCode;
            if (key == 13) {
                this.search()
            }
        }
    }
}
